// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
//
// vite docs: 'https://vite-ruby.netlify.app/guide/rails')

console.log('Vite ⚡️ Rails')

//////////////////////////////////////////
// Styling
//////////////////////////////////////////

import './application.scss';

//////////////////////////////////////////
// Core JS
//////////////////////////////////////////

import '@hotwired/turbo-rails';
import '../stimulus_setup';

import 'jquery'
import '@popperjs/core'
import 'bootstrap'

import 'trix'
import '@rails/actiontext'

//////////////////////////////////////////
// Service Worker
//////////////////////////////////////////

window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then((registration) => {
    console.log('ServiceWorker registered: ', registration);
    var serviceWorker;
    if (registration.installing) {
      serviceWorker = registration.installing;
      console.log('Service worker installing.');
    } else if (registration.waiting) {
      serviceWorker = registration.waiting;
      console.log('Service worker installed & waiting.');
    } else if (registration.active) {
      serviceWorker = registration.active;
      console.log('Service worker active.');
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError);
  });
});

//////////////////////////////////////////
// TODO: are these needed? - Unknown Misc
//////////////////////////////////////////

// import 'channels'
// const channels = import.meta.globEager('./**/*_channel.js')
// import * as ActiveStorage from '@rails/activestorage'
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
